import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import { Layout } from '../Base/Layout'
import { PageSidebar } from '../Base/PageSidebar'
import { PostsList } from '../Base/PagePostsList'
import { getSimplifiedPosts } from '../Utilities/helpers'

export default function PrjTagTemplate({ data, pageContext }){

  //const { tag } = pageContext
  //const { totalCount } = data.allMdx
  const posts = data.allMdx.edges
  const simplifiedPosts = useMemo(() => getSimplifiedPosts(posts), [posts])
  const posttype = "Speculation"

  return (
    <>
      <Layout>
        <div>
        <Helmet title="Speculation - Ten Years" />

        <article className="hero">
          <header>
            <div className="container-thoughts">
                <div className="flex-content">
                  <h1>Speculation</h1>
                </div>        
            </div>
          </header>

          <section className="container markdown-content">
            <div className="grid">
              <div className="sidebar-content">
                <PageSidebar Posttype={posttype} />
              </div>
              <div className="article-content">
                <PostsList data={simplifiedPosts} showYears/>
              </div>
            </div>
          </section>

        </article>
      </div>
      </Layout>
    </>

  )
}

export const pageQuery = graphql`
  query SpecTagPage($tag: String) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { tags: { in: [$tag] }, categories: {eq: "speculation"} } }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            categories
            published
          }
        }
      }
    }
  }
`




